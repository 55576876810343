import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { getRitoLanguageCodeFromBCP47 } from "@/app/util.mjs";
import {
  AbilityBox,
  Inline,
  ItemContainer,
  List,
  ListTitle,
  RelativeInline,
  StyledAbilityImg,
  StyledItemImg,
} from "@/game-lol/components/ChampionInsights.style.jsx";
import {
  ROLE_SYMBOL_TO_STR,
  SKILL_HOTKEYS,
} from "@/game-lol/constants/constants.mjs";
import lolRefs from "@/game-lol/refs.mjs";
import { patchDisplay } from "@/game-lol/utils/champions-stats-utils.mjs";
import { mapRoleToSymbol } from "@/game-lol/utils/util.mjs";
import { getLocale } from "@/util/i18n-helper.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const Insights = ({ championId, champion, role, patch, hideAbilitiesLink }) => {
  const { t } = useTranslation();

  const state = useSnapshot(readState);
  const lang = getLocale();
  const ritoLanguageCode = getRitoLanguageCodeFromBCP47(lang);
  const result = state.lol.championTips[ritoLanguageCode];
  const langs = Object.keys(state.lol.championTips);
  const fallBackResult = state.lol.championTips[langs[langs.length - 1]];
  const championTips = result || fallBackResult;

  const roleText =
    role &&
    ROLE_SYMBOL_TO_STR[mapRoleToSymbol(role)]?.label &&
    t(...ROLE_SYMBOL_TO_STR[mapRoleToSymbol(role)].label);

  const currentChampionTip = useMemo(() => {
    if (!Array.isArray(championTips)) return null;
    return championTips.find(
      (data) => data.championId === parseInt(championId),
    );
  }, [championTips, championId]);

  if (!championTips) {
    return <Card loading style={{ height: 500 }} />;
  }

  const title =
    role && patch
      ? t(
          "lol:championGuideRolePatch",
          "{{champion}} Guide for {{role}}, Platinum+ for Patch {{patch}}",
          {
            champion: champion?.name,
            role: roleText,
            patch: patchDisplay(patch, true),
          },
        )
      : t("lol:championGuide", "{{champion}} Guide", {
          champion: champion?.name,
        });

  const subtitle = champion?.name
    ? t(
        "lol:championNameGuideSubtitle",
        "Learn {{champion}} with our {{champion}} guide. Learn about {{champion}}'s strengths and {{champion}}'s weaknesses, and harness key insights to optimize your playstyle. Whether you're a seasoned player or new to {{champion}}, our guide provides the tools you need to dominate the battlefield.",
        { champion: champion?.name },
      )
    : t(
        "lol:championGuideSubtitle",
        "Learn more with our champion guide. Learn about their strengths and weaknesses, and harness key insights to optimize your playstyle. Whether you're a seasoned player or new to them, our guide provides the tools you need to dominate the battlefield.",
      );

  if (championTips && !currentChampionTip?.tips) {
    return (
      <Card title={title} padding="var(--sp-5)" subtitle={subtitle}>
        <p className="type-body2">
          {t(
            "lol:notFound.insights",
            "No insights data found for this champion.",
          )}
        </p>
      </Card>
    );
  }

  const {
    insights = [],
    strengths = [],
    weaknesses = [],
  } = currentChampionTip?.tips || {};

  const abilitiesLink = `/${lolRefs.lolChampionPrefix}/${champion?.key}/abilities`;

  return (
    <Card
      padding="var(--sp-5)"
      title={title}
      subtitle={subtitle}
      titleLink={abilitiesLink}
      footerButtonText={
        !hideAbilitiesLink &&
        t(
          "lol:viewChampionNamesAbilities",
          "View {{championName}}'s abilities",
          { championName: champion?.name },
        )
      }
      footerButtonLink={!hideAbilitiesLink && abilitiesLink}
    >
      <div className="flex column gap-sp-4">
        {insights.length ? (
          <div>
            <ListTitle
              className="type-title--bold"
              style={{ color: "var(--turq)" }}
            >
              {t("lol:tips.keyInsights", "Key Insights")}
            </ListTitle>
            <List>
              {currentChampionTip?.tips.insights.map((item, i) => (
                <InsightItem key={i} champion={champion} item={item} />
              ))}
            </List>
          </div>
        ) : null}
        {strengths.length ? (
          <div>
            <ListTitle
              className="type-title--bold"
              style={{ color: "var(--green)" }}
            >
              {t("lol:tips.strengths", "Strengths")}
            </ListTitle>
            <List>
              {(currentChampionTip?.tips.strengths || []).map((item, i) => (
                <InsightItem key={i} champion={champion} item={item} />
              ))}
            </List>
          </div>
        ) : null}
        {weaknesses.length ? (
          <div>
            <ListTitle
              className="type-title--bold"
              style={{ color: "var(--red)" }}
            >
              {t("lol:tips.weaknesses", "Weaknesses")}
            </ListTitle>
            <List>
              {(currentChampionTip?.tips.weaknesses || []).map((item, i) => (
                <InsightItem key={i} champion={champion} item={item} />
              ))}
            </List>
          </div>
        ) : null}
      </div>
    </Card>
  );
};

export default Insights;

function InsightItem({ champion, item, ...restProps }) {
  const renderData = useMemo(() => {
    if (!item || !item.value) return [];

    const text = item.localizedInsights?.[0]?.translation
      ? item.localizedInsights[0].translation
      : item.value;
    const values = text.split("@");
    const re = item.value.match(/@(.*?)@/g);
    const symbols = re ? re.map((s) => s.replace(/@/g, "")) : [];

    return values.map((value) => {
      if (symbols.includes(value)) {
        const splitValue = value.split("_");
        const [type] = splitValue;
        const id = splitValue[1];
        switch (type) {
          case "spell":
            if (id === "P") {
              return (
                <StyledAbilityImg champion={champion}>
                  <AbilityBox
                    className={`box-skill--${SKILL_HOTKEYS.indexOf(id) + 1}`}
                  >
                    {/* eslint-disable-next-line */}
                    <span className="type-overline ability-text">P</span>
                  </AbilityBox>
                </StyledAbilityImg>
              );
            }
            return (
              <RelativeInline>
                <StyledAbilityImg
                  abilityIndex={SKILL_HOTKEYS.indexOf(id) + 1}
                  champion={champion}
                >
                  <AbilityBox
                    className={`box-skill--${SKILL_HOTKEYS.indexOf(id) + 1}`}
                  >
                    <span className="type-overline ability-text">{id}</span>
                  </AbilityBox>
                </StyledAbilityImg>
              </RelativeInline>
            );
          case "item":
            return <StyledItemImg itemId={id} />;
          default:
            return null;
        }
      }
      return value;
    });
  }, [champion, item]);

  return (
    <ItemContainer {...restProps}>
      <div className="type-body2">
        {renderData.map((data, index) => (
          <Inline key={index}>{data}</Inline>
        ))}
      </div>
    </ItemContainer>
  );
}
