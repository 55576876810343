import React from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";
import Card from "clutch/src/Card/Card.jsx";

import { readState } from "@/__main__/app-state.mjs";
import ChampionMatchupImg from "@/game-lol/components/ChampionMatchupImg.jsx";
import lolRefs from "@/game-lol/refs.mjs";
import {
  getChampionLink,
  getLaneWinRateColor,
  getStaticData,
} from "@/game-lol/utils/util.mjs";
import DataTable from "@/shared/DataTable";
import { getLocale } from "@/util/i18n-helper.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const ChampionMeta = () => css`
  color: red;
  @container content-card (inline-size <= 500px) {
    .name-string {
      width: 4ch;
    }
  }
`;

const MatchupsList = ({
  title,
  subtitle,
  champion,
  championId,
  urlParams,
  limit = 0,
}) => {
  const { t } = useTranslation();
  const champions = getStaticData("champions");

  const {
    lol: { championMatchups },
  } = useSnapshot(readState);

  const matchups = championMatchups[championId]?.[urlParams];

  if (!matchups || matchups instanceof Error) {
    return <Card loading style={{ height: 480 }} />;
  }

  const totalMatchupsGames = matchups.reduce((acc, curr) => {
    return acc + (curr.games || 0);
  }, 0);

  const matchupsData = matchups.map((matchup) => {
    const {
      matchup_champion_id: opponentChampionId,
      win_rate: gameWinrate,
      lane_win_rate: laneWinrate,
      games,
    } = matchup;
    const championKey = champions?.keys?.[opponentChampionId];
    const champion = champions?.[championKey];
    const championName = champion?.name;

    const matchupRate = games / totalMatchupsGames;

    return {
      championKey,
      championName,
      opponentChampionId,
      gameWinrate,
      laneWinrate,
      matchupRate,
      games,
    };
  });

  const renderedMatchups = limit ? matchupsData.slice(0, limit) : matchupsData;

  return (
    <Card
      padding="0"
      title={title}
      subtitle={subtitle}
      titleLink={getChampionLink(champion?.key, "counters")}
      footerButtonText={t("common:viewAllMatchups", "View All Matchups")}
      footerButtonLink={getChampionLink(champion?.key, "counters")}
    >
      <DataTable
        borderless
        sortCol={2} // lane win rate
        sortDir="DESC"
        cols={[
          {
            display: t("lol:champion", "Champion"),
            align: "left",
            primary: true,
          },
          {
            display: t("lol:stats.gameWinRate", "Game Win Rate"),
            isStat: true,
          },
          {
            display: t("lol:stats.laneWinRate", "Lane Win Rate"),
            isStat: true,
          },
          { display: t("lol:stats.matchupRate", "Matchup Rate"), isStat: true },
          { display: t("common:matches", "Matches"), isStat: true },
        ]}
        rows={renderedMatchups.map((matchup) => {
          const {
            championKey,
            opponentChampionId,
            championName,
            gameWinrate,
            laneWinrate,
            matchupRate,
            games,
          } = matchup;

          // We want to display the win rate of the champion whose page we are on
          const displayGameWinrate = 1 - gameWinrate;
          const displayedLaneWinrate = 1 - laneWinrate;
          const opponentWins = displayedLaneWinrate < 0.5;

          return [
            {
              display: (
                <>
                  <ChampionMatchupImg
                    championId={opponentChampionId}
                    winRateColor={
                      opponentWins
                        ? getLaneWinRateColor(displayedLaneWinrate * 100)
                        : "var(--shade5)"
                    }
                  />
                  <span className="name-string">
                    {championName || opponentChampionId}
                  </span>
                </>
              ),
              value: championName,
              link: `/${lolRefs.lolChampionPrefix}/${championKey}`,
              style: { gap: "var(--sp-3)" },
              className: ChampionMeta(),
            },
            {
              display: displayGameWinrate.toLocaleString(getLocale(), {
                style: "percent",
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              }),
              value: gameWinrate,
            },
            {
              display: displayedLaneWinrate.toLocaleString(getLocale(), {
                style: "percent",
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              }),
              value: laneWinrate,
            },
            {
              display: matchupRate.toLocaleString(getLocale(), {
                style: "percent",
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              }),
              value: matchupRate,
            },
            { display: games.toLocaleString(getLocale()), value: games },
          ];
        })}
      />
    </Card>
  );
};

export default MatchupsList;
