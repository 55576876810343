import React, { useCallback, useMemo, useState } from "react";

import { updateRoute } from "@/__main__/router.mjs";
import ChampionFilter from "@/game-lol/components/ChampionFilter.jsx";
import { FILTER_SYMBOLS } from "@/game-lol/constants/constants.mjs";
import lolRefs from "@/game-lol/refs.mjs";
import { getDefaultedFiltersForChampions } from "@/game-lol/utils/util.mjs";
import { useTransientRoute } from "@/util/router-hooks.mjs";
import { useInteractionEvent } from "@/util/use-interaction-event.mjs";

// {matchupChampion, role, rank, region, queue} = filterParams
const navigateToRoute = (championKey, tab, matchupChampionKey, urlParams) => {
  const pathname = `/${lolRefs.lolChampionPrefix}/${championKey}/${tab}/${
    matchupChampionKey || ""
  }`;
  updateRoute(pathname, urlParams.toString());
};

const useChampionFilter = (tab, champion, matchupChampion) => {
  // tab is one of "build" | "arena" | "aram" | "probuilds" | "trends" | "counters"
  const {
    // parameters: [championKey],
    currentPath,
    searchParams,
  } = useTransientRoute();

  const sendInteractionEvent = useInteractionEvent("champion-page-filter");
  const championKey = champion?.key;

  const filters = getDefaultedFiltersForChampions(searchParams, "overview");

  const [victoryOnly, setVictoryOnly] = useState(false);

  const doFilter = useCallback(
    (filterParams) => {
      const filters = {
        role: filterParams.role,
        tier: filterParams.tier,
        queue: filterParams.queue,
        region: filterParams.region,
      };
      const params = new URLSearchParams(filters);
      navigateToRoute(championKey, tab, filterParams.matchup, params);
    },
    [tab, championKey],
  );

  const setFilter = useCallback(
    (key, val) => {
      const selectedMatchupChampion = currentPath.split("/")[5];
      if (selectedMatchupChampion) {
        filters.matchup = selectedMatchupChampion;
      }
      switch (key) {
        case FILTER_SYMBOLS.matchup:
          doFilter({ ...filters, matchup: val });
          sendInteractionEvent("matchup");
          break;
        case FILTER_SYMBOLS.role:
          doFilter({ ...filters, role: val });
          sendInteractionEvent("role");
          break;
        case FILTER_SYMBOLS.tier:
          doFilter({ ...filters, tier: val });
          sendInteractionEvent("rank");
          break;
        case FILTER_SYMBOLS.queue:
          doFilter({ ...filters, queue: val });
          sendInteractionEvent("queue");
          break;
        case FILTER_SYMBOLS.region:
          doFilter({ ...filters, region: val });
          sendInteractionEvent("region");
          break;
        case FILTER_SYMBOLS.victoryOnly:
          setVictoryOnly(val);
          sendInteractionEvent("victory-only-toggle");
          break;
        case FILTER_SYMBOLS.team:
          doFilter({ ...filters, team: val });
          sendInteractionEvent("team-select");
          break;
      }
    },
    [filters, doFilter, currentPath, sendInteractionEvent],
  );

  // champions filter bar
  const View = useMemo(() => {
    return (
      <ChampionFilter
        champion={champion}
        searchText={filters.searchText}
        role={filters.role || champion.role}
        tier={filters.tier}
        region={filters.region}
        queue={filters.queue}
        team={filters.team}
        victoryOnly={victoryOnly}
        setFilter={setFilter}
        tab={tab}
        matchupChampion={matchupChampion}
      />
    );
  }, [champion, tab, filters, matchupChampion, victoryOnly, setFilter]);

  return {
    FilterBar: View,
    victoryOnly,
    ...filters,
  };
};

export default useChampionFilter;
