import React from "react";
import { Card } from "clutch/src/Card/Card.jsx";

import { readState } from "@/__main__/app-state.mjs";
import ProBuildSummary from "@/game-lol/components/ProBuildSummary.jsx";
import ProMatchesList from "@/game-lol/components/ProMatchesList.jsx";
import { getProMatchlistQueryString } from "@/game-lol/utils/probuilds-utils.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const ChampionProbuilds = ({ championId, role, victoryOnly }) => {
  const {
    lol: { proBuildMatchlist },
  } = useSnapshot(readState);

  const queryString = getProMatchlistQueryString(championId, role);

  const matches = proBuildMatchlist[queryString];
  const filteredMatches = victoryOnly
    ? matches?.filter((e) => e.win === true)
    : matches;

  return (
    <Card style={{ display: "grid", gap: "var(--sp-4)" }}>
      {filteredMatches?.length ? (
        <ProBuildSummary matches={filteredMatches} />
      ) : null}
      <ProMatchesList loading={!matches} matches={filteredMatches || []} />
    </Card>
  );
};

export default ChampionProbuilds;
