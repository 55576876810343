import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { mobile } from "clutch/src/Style/style.mjs";

import { readState } from "@/__main__/app-state.mjs";
import { IS_APP } from "@/__main__/constants.mjs";
import ChampionAbilities from "@/game-lol/components/ChampionAbilities.jsx";
import ChampionHeaderImage from "@/game-lol/components/ChampionHeaderImage.jsx";
import ChampionImg from "@/game-lol/components/ChampionImg.jsx";
import { CHAMPIONS } from "@/game-lol/constants/champion-classes.mjs";
import {
  QUEUE_SYMBOLS,
  RANK_SYMBOL_TO_STR,
  ROLE_SYMBOL_TO_STR,
} from "@/game-lol/constants/constants.mjs";
import type { ChampionStats } from "@/game-lol/models/lol-champions-stats.mjs";
import lolRefs from "@/game-lol/refs.mjs";
import { CHAMPION_TABS } from "@/game-lol/routes.mjs";
import { patchDisplay } from "@/game-lol/utils/champions-stats-utils.mjs";
import Static from "@/game-lol/utils/static.mjs";
import { getQueueDetails } from "@/game-lol/utils/symbol-queue.mjs";
import RoleSymbol from "@/game-lol/utils/symbol-role.mjs";
import useStaticChampionByKey from "@/game-lol/utils/use-static-champion-by-id.mjs";
import {
  getChampionLink,
  getLaneWinRateColor,
} from "@/game-lol/utils/util.mjs";
import getTierColor from "@/shared/get-tier-color.mjs";
import getTierIcon from "@/shared/get-tier-icon-path.mjs";
import PageHeader from "@/shared/PageHeader.jsx";
import { getLocaleString } from "@/util/i18n-helper.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import SuppressHydrationWarnings from "@/util/SuppressHydrationWarnings.jsx";
import SymbolMap from "@/util/symbol-map.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const Caption = styled("p")`
  text-align: right;
  color: var(--shade1);
`;
const WinRate = styled("span")`
  color: ${(props) => props.color || "var(--shade1)"};
  text-align: center;
`;
const Champion = styled("div")`
  display: flex;
  align-items: center;
  gap: var(--sp-4);

  &.matchup {
    text-align: right;

    .vs {
      color: var(--shade2);
      margin-right: var(--sp-2);
    }

    ${mobile} {
      display: none;
    }
  }
`;
const Matchups = styled("div")`
  display: flex;
  flex-direction: column;

  ${mobile} {
    display: none;
  }
`;
const TierContainer = styled("div")`
  .tier-bg {
    fill: var(--shade9);
  }
`;

type Matchup = {
  opponentChampionId: number;
  laneWins: number;
  games: number;
};
type ChampionFilters = {
  role: string;
  tier: string;
  queue: string;
};
type ChampionHeaderProps = {
  tab: string;
  championId: number;
  championKey: string;
  championName: string;
  championStats?: ChampionStats;
  matchupChampionId?: number;
  matchupChampionKey?: string;
  matchupChampionName?: string;
  matchups?: Matchup[];
  filters?: ChampionFilters;
};

const ChampionHeader = ({
  tab,
  championId,
  championKey,
  championName,
  championStats,
  matchupChampionId,
  matchupChampionKey,
  matchupChampionName,
  matchups,
  filters,
}: ChampionHeaderProps) => {
  const { searchParams: urlParams } = useRoute();
  const { t } = useTranslation();
  const {
    lol: { arenaStatsPatch },
  } = useSnapshot(readState);

  const champion = useStaticChampionByKey(championKey);
  const matchupChampion = useStaticChampionByKey(matchupChampionKey);

  const isMatchupsAvailable = matchups?.length > 0;
  const championTier =
    !champion?.prerelease && championStats?.champion_role_tier;
  const TierIcon = !champion?.prerelease && getTierIcon(championTier);
  const searchParams = urlParams.toString();

  const links = Object.values(CHAMPION_TABS)
    .filter((tab) => tab.live)
    .map((tab) => {
      const url = tab.useParams
        ? `/${lolRefs.lolChampionPrefix}/${championKey}${tab.url}?${searchParams}`
        : `/${lolRefs.lolChampionPrefix}/${championKey}${tab.url}`;

      return {
        url,
        routeMatch: tab.routeMatch,
        text: t(...tab.name),
      };
    });

  const aside = (
    <>
      {(matchupChampion || matchupChampionKey) && (
        <Champion className="matchup">
          <div>
            <h2 className="type-page-header">
              <span className="vs">{t("lol:vs", "vs")}</span>
              <span>{matchupChampionName || matchupChampionKey}</span>
            </h2>

            <ChampionAbilities champion={matchupChampion} />
          </div>

          <a href={getChampionLink(championKey, tab, undefined, filters)}>
            <div>
              <ChampionHeaderImage
                championId={matchupChampionId}
                isMatchupChampion={true}
                size={72}
              />
            </div>
          </a>
        </Champion>
      )}

      {isMatchupsAvailable && !matchupChampionKey && (
        <Matchups className="gap-sp-1">
          <Caption className="type-caption">
            {IS_APP
              ? t("lol:championsPage.toughestMatchups", "Toughest Matchups:")
              : t("lol:championCounters", "{{champion}} Counters", {
                  champion: championName || championKey,
                })}
          </Caption>
          <ol className="flex justify-end gap-sp-1">
            <SuppressHydrationWarnings>
              {matchups.map(({ opponentChampionId, laneWins, games }) => {
                const winrate = laneWins / (games || 1);
                const opponentChampionKey = CHAMPIONS[opponentChampionId]?.key;
                if (!opponentChampionKey) return null;
                return (
                  <a
                    key={opponentChampionId}
                    aria-label={t(
                      "lol:championsPage.addChampionMatchup",
                      "Add {{champion}} Matchup",
                      { champion: championKey },
                    )}
                    href={`/${lolRefs.lolChampionPrefix}/${championKey}/build/${opponentChampionKey}?${searchParams}`}
                  >
                    <li className="flex column">
                      <ChampionImg
                        size={32}
                        championId={opponentChampionId}
                        round={false}
                      />

                      <WinRate
                        color={getLaneWinRateColor(winrate * 100)}
                        className="type-caption"
                        suppressHydrationWarning
                      >
                        {getLocaleString(winrate, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                          style: "percent",
                        })}
                      </WinRate>
                    </li>
                  </a>
                );
              })}
            </SuppressHydrationWarnings>
          </ol>
        </Matchups>
      )}
    </>
  );

  const role = ROLE_SYMBOL_TO_STR[RoleSymbol(filters.role)]
    ?.label as Translation;

  const rank = SymbolMap.entries(RANK_SYMBOL_TO_STR).find(([_, { gql }]) =>
    filters.tier ? filters.tier === gql : true,
  )?.[1]?.t;

  const isArena =
    tab === "arena" ||
    filters.queue === getQueueDetails(QUEUE_SYMBOLS.arena).gql;

  const patch =
    /* @ts-ignore */
    isArena && arenaStatsPatch ? arenaStatsPatch.patch : championStats?.patch;

  const title =
    patch && CHAMPION_TABS[tab]?.titleMetaInfo
      ? CHAMPION_TABS[tab].titleMetaInfo({
          patchNumber: patchDisplay(patch, true),
          role: Array.isArray(role) ? t(...role) : role,
          rank: rank && t(rank.name, rank.fallback),
        })
      : ["lol:championspage.tabs.fallbackTitle", "Build & Runes"];

  const image = champion?.prerelease
    ? Static.getChampionPreReleaseImage(championKey)
    : Static.getChampionImage(championId);

  return (
    <PageHeader
      title={
        <span>
          <span>{championName || championKey} </span>
          <span className="shade2">
            {!champion?.prerelease
              ? t(...title)
              : t("common:comingSoonNormal", "Coming Soon")}
          </span>
        </span>
      }
      image={image}
      imageAlt={championName || championKey}
      imageAccent={
        TierIcon && (
          <TierContainer>
            <img src={TierIcon} width="32" height="36" />
          </TierContainer>
        )
      }
      borderColor={championTier && getTierColor(championTier)}
      underTitle={
        !champion?.prerelease && <ChampionAbilities champion={champion} />
      }
      links={champion?.prerelease ? null : links}
      aside={aside}
      // counterModeFn={(link, currentPath, searchParams, classes) => {
      //   let className, linkUrl;
      //   const link_to_seq = link.url.split("/");
      //   const path_to_seq = currentPath.split("/");
      //   // e.g. /lol/champions/X/o/Y
      //   //     ^ ^   ^         ^ ^ ^
      //   if (path_to_seq.length === 6) {
      //     const objY = path_to_seq.pop();
      //     if (objY) {
      //       const pathO = path_to_seq.pop();
      //       const linkO =
      //         link_to_seq.length === 4 ? "build" : link_to_seq.pop();
      //       if (linkO === "probuilds") return null;
      //       const pathPrefix = path_to_seq.join("/");
      //       const linkPrefix = path_to_seq.join("/");
      //       if (pathPrefix === linkPrefix && pathO === linkO) {
      //         className = classes.active;
      //       } else {
      //         className = classes.standard;
      //       }
      //       const params = [];
      //       for (const key of searchParams.keys()) params.push(key);
      //       const paramsStr = params
      //         .map(
      //           (x) =>
      //             `${encodeURIComponent(x)}=${encodeURIComponent(
      //               searchParams.get(x),
      //             )}`,
      //         )
      //         .join("&");
      //       linkUrl = `${pathPrefix}/${linkO}/${objY}${
      //         paramsStr ? "?" + paramsStr : ""
      //       }`;
      //       return { url: linkUrl, class: className };
      //     }
      //   }
      //   return null;
      // }}
    />
  );
};

export default ChampionHeader;
