import React from "react";
import { useTranslation } from "react-i18next";
import Card from "clutch/src/Card/Card.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { Container } from "@/game-lol/components/ChampionMatchupColumns.style.jsx";
import ChampionMatchupImg from "@/game-lol/components/ChampionMatchupImg.jsx";
import lolRefs from "@/game-lol/refs.mjs";
import {
  gameLosingMatchups,
  laneLosingMatchups,
} from "@/game-lol/utils/champion-matchups-util.mjs";
import {
  getLaneWinRateColor,
  getStaticData,
  getWinRateColor,
} from "@/game-lol/utils/util.mjs";
import DataTable from "@/shared/DataTable";
import { getLocale } from "@/util/i18n-helper.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function MatchupColumns({ championId, champion, urlParams = "" }) {
  const { t } = useTranslation();
  const champions = getStaticData("champions");

  const {
    lol: { championMatchups },
  } = useSnapshot(readState);

  const matchups = championMatchups[championId]?.[urlParams];

  if (!matchups) {
    return (
      <div className={Container()}>
        <div className="columns">
          <Card loading style={{ height: 600 }} />
          <Card loading style={{ height: 600 }} />
        </div>
      </div>
    );
  }

  const laneWinList = laneLosingMatchups([...matchups]).map((matchup) => {
    const championKey = champions?.keys?.[matchup.matchup_champion_id];
    const champion = champions?.[championKey];
    const championName = champion?.name;
    return {
      ...matchup,
      championKey,
      championName,
    };
  });
  const gameWinList = gameLosingMatchups([...matchups]).map((matchup) => {
    const championKey = champions?.keys?.[matchup.matchup_champion_id];
    const champion = champions?.[championKey];
    const championName = champion?.name;
    return {
      ...matchup,
      championKey,
      championName,
    };
  });

  return (
    <div className={Container()}>
      <div className="columns">
        <Card
          padding="0"
          title={
            champion?.name
              ? t(
                  "lol:championNamesGameCounters",
                  "{{championName}}'s Game Counters",
                  {
                    championName: champion.name,
                  },
                )
              : t("common:gameCounters", "Game Counters")
          }
          subtitle={
            champion?.name
              ? t(
                  "lol:championNameGameCountersSubtitle",
                  "The below champions are especially good against {{championName}} specificially when it comes to winning the game. The win rates shown is {{championName}}'s win rate against these champions.",
                  {
                    championName: champion.name,
                  },
                )
              : t(
                  "lol:gameCountersSubtitle",
                  "The below champions are especially good at winning the game against this champion.",
                )
          }
        >
          <DataTable
            borderless
            sortCol={1} // game win rate
            sortDir="DESC"
            cols={[
              {
                display: t("lol:champion", "Champion"),
                align: "left",
                primary: true,
              },
              {
                display: t("lol:stats.gameWinRate", "Game Win Rate"),
                isStat: true,
              },
              {
                display: t("common:matches", "Matches"),
                isStat: true,
              },
            ]}
            rows={gameWinList.map((matchup) => {
              const {
                championKey,
                matchup_champion_id,
                championName,
                win_rate,
                games,
              } = matchup;

              // We want to display the win rate of the champion whose page we are on
              const displayGameWinrate = win_rate;
              const winRateColor = getWinRateColor(win_rate * 100);

              return [
                {
                  display: (
                    <>
                      <ChampionMatchupImg
                        championId={matchup_champion_id}
                        winRateColor={winRateColor}
                      />
                      <span className="name-string">
                        {championName || matchup_champion_id}
                      </span>
                    </>
                  ),
                  value: championName,
                  link: `/${lolRefs.lolChampionPrefix}/${championKey}`,
                  style: {
                    gap: "var(--sp-3)",
                  },
                },
                {
                  display: displayGameWinrate.toLocaleString(getLocale(), {
                    style: "percent",
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 1,
                  }),
                  value: 1 - win_rate,
                  // pillsColor: winRateColor,
                },
                {
                  display: games.toLocaleString(getLocale()),
                  value: games,
                },
              ];
            })}
          />
        </Card>
        <Card
          padding="0"
          title={
            champion?.name
              ? t(
                  "lol:championNamesLaneCounters",
                  "{{championName}}'s Lane Counters",
                  {
                    championName: champion.name,
                  },
                )
              : t("common:gameCounters", "Game Counters")
          }
          subtitle={
            champion?.name
              ? t(
                  "lol:championNameLaneCountersSubtitle",
                  "The below champions are especially good against {{championName}} during lane phase (gold difference @14 mins). The win rates shown is {{championName}}'s win rate against these champions.",
                  {
                    championName: champion.name,
                  },
                )
              : t(
                  "lol:laneCountersSubtitle",
                  "The below champions are especially good at winning lane against this champion.",
                )
          }
        >
          <DataTable
            borderless
            sortCol={1} // lane win rate
            sortDir="DESC"
            cols={[
              {
                display: t("lol:champion", "Champion"),
                align: "left",
                primary: true,
              },
              {
                display: t("lol:stats.laneWinRate", "Lane Win Rate"),
                isStat: true,
              },
              {
                display: t("common:matches", "Matches"),
                isStat: true,
              },
            ]}
            rows={laneWinList.map((matchup) => {
              const {
                championKey,
                matchup_champion_id,
                championName,
                lane_win_rate,
                games,
              } = matchup;

              // We want to display the win rate of the champion whose page we are on
              const displayedLaneWinrate = lane_win_rate;
              const winRateColor = getLaneWinRateColor(
                displayedLaneWinrate * 100,
              );

              return [
                {
                  display: (
                    <>
                      <ChampionMatchupImg
                        championId={matchup_champion_id}
                        winRateColor={winRateColor}
                      />
                      <span className="name-string">
                        {championName || matchup_champion_id}
                      </span>
                    </>
                  ),
                  value: championName,
                  link: `/${lolRefs.lolChampionPrefix}/${championKey}`,
                  style: { gap: "var(--sp-3)" },
                },
                {
                  display: displayedLaneWinrate.toLocaleString(getLocale(), {
                    style: "percent",
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 1,
                  }),
                  value: 1 - lane_win_rate,
                  // pillsColor: winRateColor,
                },
                {
                  display: games.toLocaleString(getLocale()),
                  value: games,
                },
              ];
            })}
          />
        </Card>
      </div>
    </div>
  );
}

export default MatchupColumns;
