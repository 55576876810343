import React from "react";

import ChampionBuilds from "@/game-lol/components/ChampionBuilds.jsx";
import ChampionInsights from "@/game-lol/components/ChampionInsights.jsx";
import { QUEUE_SYMBOLS } from "@/game-lol/constants/constants.mjs";
import { QUEUE_SYMBOL_TO_OBJECT } from "@/game-lol/utils/symbol-queue.mjs";
import { getWinRateColor } from "@/game-lol/utils/util.mjs";
import ChampionStatsBar from "@/shared/CharacterStatsBar.jsx";
import getTierIcon from "@/shared/get-tier-icon-path.mjs";

const OFA = QUEUE_SYMBOL_TO_OBJECT[QUEUE_SYMBOLS.oneForAll];

function ChampionURF({ champion, championStats, filters }) {
  if (OFA.comingSoon) return null;

  const renderedStats = championStats
    ? [
        {
          key: "winrate",
          title: ["lol:winRate", "Win Rate"],
          value: championStats.win_rate,
          color: getWinRateColor(championStats.win_rate * 100),
          format: {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
            style: "percent",
          },
        },
        {
          key: "pickrate",
          title: ["lol:pickRate", "Pick Rate"],
          value: championStats.champion_role_pickrate,
          color: "",
          format: {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
            style: "percent",
          },
        },
        {
          key: "banrate",
          title: ["lol:banRate", "Ban Rate"],
          value: championStats.champion_ban_rate,
          color: "",
          format: {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
            style: "percent",
          },
        },
        {
          key: "matches",
          title: ["common:matches", "Matches"],
          value: championStats.games,
          color: "",
          format: {},
        },
      ]
    : [];

  if (championStats?.champion_role_tier) {
    const Icon = getTierIcon(championStats.champion_role_tier);
    renderedStats.unshift({
      key: "tier",
      title: ["common:tier", "Tier"],
      value: championStats.champion_role_tier,
      color: "",
      format: {},
      icon: <img src={Icon} width="36" height="36" />,
    });
  }

  return (
    <>
      <ChampionStatsBar stats={renderedStats} loading={!championStats} />
      <ChampionBuilds
        champion={champion}
        championId={champion?.id}
        queue={OFA.gql}
        region={filters.region}
      />
      <ChampionInsights championId={champion?.id} champion={champion} />
    </>
  );
}

export default ChampionURF;
